import SchoolResultsSlider from 'components/digital-solutions/SchoolResultsSlider'
import Resource from 'components/global/Resource'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

export default function ResearchAndResultsPage() {
	return (
		<Layout pageName="Research & Results">
			<Section flatGray>
				<Row middle className="pb-12 pt-12 md:pb-16">
					<Col width="w-full md:w-1/2">
						<div>
							<h1 className="mb-3 text-3xl font-medium leading-tight tracking-normal sm:mb-6 sm:text-4xl md:text-5xl">Research & Results</h1>
							<p className="text-lg leading-snug sm:text-xl">
								ARC’s comprehensive literacy solutions have been developed through years of field experience across all 50 states and are grounded in seminal
								research in the field and the science of learning. An ever-expanding library of results and research speaks to the transformative power of these
								literacy interventions in a variety of contexts and communities.
							</p>
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="hidden md:block">
						<img src="/images/research-and-results/research-graph.svg" alt="graph" className="max-h-96" />
					</Col>
				</Row>
			</Section>

			<Section margin="mt-20">
				<Row className="py-10">
					<Col>
						<Tabs selectedTabClassName="text-ab-100 border-ab-100">
							<TabList className="mb-6 flex gap-4 overflow-x-auto px-4">
								<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">Research</Tab>
								<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">Results</Tab>
								{/* <Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">Delete?</Tab> */}
							</TabList>
							{/* Research */}
							<TabPanel>
								<Row center>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="ARC Core IES Efficacy Study" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">Key findings from the Institute of Education Sciences (IES)</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="Journal of Research on Educational Effectiveness" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">
												ARC Core efficacy study published in the <em>Journal of Research on Educational Effectiveness</em>
											</span>
										</div>
									</Col>

									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="Offenberg Study" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">Impact of 100 Book Challenge on grades 1–3 students</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="An Evaluation of the 100 Book Challenge Program (William Penn)" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">An Evaluation of the 100 Book Challenge Program (William Penn)</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="An Evaluation of the 100 Book Challenge Program" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">An Evaluation of the 100 Book Challenge Program</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="Validity of the IRLA" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">Study of IRLA validity conducted by American Reading Company</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="Concurrent Validity of the IRLA" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">IRLA validity study published in The Journal of At–Risk Issues</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="Measurement Inc. Report" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">
												Study of IRLA validity and educational effectiveness conducted by <em>Measurement Incorporated</em>
											</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="Why a Structured Phonics Program is Effective" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">Why a Structured Phonics Program is Effective</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource external title="Evidence for ESSA" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">ARC Core Evidence for ESSA</span>
										</div>
									</Col>
								</Row>
							</TabPanel>
							{/* Results */}
							<TabPanel>
								<Row center>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="Research & Results" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">Research & Results Summary</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="ARC Core Results Packet" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">Summary of Results in ARC Core Districts</span>
										</div>
									</Col>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="Philadelphia Schools Beating the Odds" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">Philadelphia Schools Beating the Odds</span>
										</div>
									</Col>
								</Row>
							</TabPanel>
							{/* <TabPanel>
								<Row center>
									<Col width="w-full md:w-1/4" className="mb-6">
										<Resource title="Adoption, Implementation, and Effects of Curriculum Materials" />
										<div className="mt-4 text-center">
											<span className="text-xl font-medium">Adoption, Implementation, and Effects of Curriculum Materials</span>
										</div>
									</Col>
									
								</Row>
							</TabPanel> */}
						</Tabs>
					</Col>
				</Row>
				<Row className="mt-20">
					<Col>
						<SchoolResultsSlider />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
